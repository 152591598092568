<template>
	<div>
		<v-container fluid>
			<v-row class="mx-md-2">
				<v-col cols="12">
					<v-card v-bind="customCard">
						<v-data-table :headers="headers" :items="categories.data"
									  :loading="listStatus === status.RUNNING" disable-filtering disable-pagination
									  disable-sort hide-default-footer loading-text="Loading... Please wait">
							<template v-slot:top>
								<v-toolbar id="categories-toolbar" class="rounded-t" flat>
									<v-toolbar-title>
										{{ filters.archived ? 'Archived Categories' : 'Categories' }}
									</v-toolbar-title>

									<v-spacer></v-spacer>

									<v-btn :disabled="filters.archived" @click="createDialog = true" class="mr-2"
										   color="primary">
										<v-icon>
											mdi-plus
										</v-icon>
									</v-btn>

									<table-filters v-model="filters" :filter-types="filterTypes"
												   right-margin></table-filters>

									<v-btn @click="refreshData" color="primary">
										<v-icon>
											mdi-refresh
										</v-icon>
									</v-btn>
								</v-toolbar>
							</template>

							<template v-slot:item.name="props">
								<v-edit-dialog v-if="!filters.archived" @save="updateCategory(props.item)"
											   :return-value.sync="props.item.name" large>
									{{ props.item.name }}
									<template v-slot:input>
										<div class="mt-4 mb-2 text-h6">
											Update Name
										</div>
										<v-text-field v-model="props.item.name"
													  :rules="[validation.required, validation.notEmpty]" autofocus
													  counter="100" dense label="Name" outlined
													  single-line></v-text-field>
									</template>
								</v-edit-dialog>
								<div v-else>
									{{ props.item.name }}
								</div>
							</template>

							<template v-slot:item.accessoryGroup="props">
								<v-edit-dialog v-if="!filters.archived" @save="updateCategory(props.item)"
											   :return-value.sync="props.item.accessoryGroupId" large>
									{{ groupNameForId(props.item.accessoryGroupId) }}
									<template v-slot:input>
										<div class="mt-4 mb-2 text-h6">
											Update Group
										</div>
										<v-select v-model="props.item.accessoryGroupId" :items="accessoryGroups" dense
												  hide-details item-text="name" item-value="id" outlined
												  single-line></v-select>
									</template>
								</v-edit-dialog>
								<div v-else>
									{{ groupNameForId(props.item.accessoryGroupId) }}
								</div>
							</template>

							<template v-slot:item.active="{item}">
								<v-checkbox v-model="item.active" :disabled="filters.archived"
											@change="updateCategory(item)" class="ma-0 pa-0" color="primary"
											hide-details></v-checkbox>
							</template>

							<template v-slot:item.action="props">
								<v-icon v-if="!filters.archived" @click="archiveId = props.item.id">
									mdi-archive
								</v-icon>
								<v-icon v-else @click="restoreId = props.item.id">
									mdi-backup-restore
								</v-icon>
							</template>

						</v-data-table>

						<v-pagination v-model="filters.page" :disabled="listStatus === status.RUNNING"
									  :length="categories.pages" :total-visible="7" class="my-4"
									  color="secondary"></v-pagination>


					</v-card>
				</v-col>
			</v-row>
		</v-container>


		<card-dialog :showing="createDialog" @cancel="createDialog = false" @confirmed="createCategory(category)"
					 :confirm-disabled="!newCategoryForm" confirm-text="Create" max-width="600px"
					 title="Create Category">
			<template v-slot:dialog-body>
				<v-form v-model="newCategoryForm" ref="newCategoryForm">
					<v-row dense>
						<v-col cols="12">
							<v-text-field v-model="category.name" :id="newUUID()"
										  :rules="[validation.required, validation.notEmpty]"
										  autocomplete="new-password" counter="100" dense label="Name"
										  outlined></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-select v-model="category.accessoryGroupId" :items="accessoryGroups"
									  :rules="[validation.required]" dense item-text="name" item-value="id"
									  label="Accessory Group" outlined></v-select>
						</v-col>
					</v-row>
				</v-form>
			</template>
		</card-dialog>

		<card-dialog :showing="archiveId !== null" @cancel="archiveId = null" @confirmed="archiveCategory(archiveId)"
					 confirm-color="warning" confirm-text="Archive" title="Archive Category" title-color="warning">
			<template v-slot:dialog-body>
				Are you sure you want to archive this category?
			</template>
		</card-dialog>

		<card-dialog :showing="restoreId !== null" @cancel="restoreId = null" @confirmed="restoreCategory(restoreId)"
					 confirm-color="warning" confirm-text="Restore" title="Restore Category" title-color="warning">
			<template v-slot:dialog-body>
				Are you sure you want to restore this category?
			</template>
		</card-dialog>

	</div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import CardDialog from '@/components/global/CardDialog'
import TableFilters from '@/components/global/TableFilters'

export default {
	name: 'CategoriesIndex',
	components: {TableFilters, CardDialog},
	data: () => ({
		headers: [
			{text: 'Name', align: 'left', value: 'name', width: '35%'},
			// {text: 'Slug', value: 'slug', width: '25%'},
			{text: 'Group', value: 'accessoryGroup', width: '25%'},
			{text: 'Active', value: 'active', width: '15%'},
			{text: 'Maintainer', value: 'lastMaintainedBy', width: '15%'},
			{text: '', value: 'action', align: 'right', width: '10%'},
		],
		filters: {},
		newCategoryForm: false,
		category: {
			name: '',
		},
		createDialog: false,
		archiveId: null,
		restoreId: null,
	}),
	created() {
		this.filters = {...this.appliedFilters}
		this.listAllAccessoryGroups()
	},
	methods: {
		...mapActions('accessory/accessoryGroup', [
			'listAllAccessoryGroups'
		]),
		...mapActions('accessory/category', [
			'applyFilters',
			'listCategories',
			'createCategory',
			'updateCategory',
			'archiveCategory',
			'restoreCategory',
		]),
		refreshData() {
			this.listCategories()
			this.listAllAccessoryGroups()
		},
		groupNameForId(id) {
			for (let i = 0; i < this.accessoryGroups.data.length; i++) {
				if (this.accessoryGroups.data[i].id === id) {
					return this.accessoryGroups.data[i].name
				}
			}
			return ''
		}
	},
	computed: {
		...mapState('accessory/category', [
			'appliedFilters',
			'categories',
			'listStatus',
			'createStatus',
			'updateStatus',
			'archiveStatus',
			'restoreStatus'
		]),
		...mapState('accessory/accessoryGroup', [
			'accessoryGroups',
		]),
		filterTypes() {
			return [
				{
					label: 'Name',
					type: 'text',
					name: 'name'
				},
				{
					label: 'Accessory Group',
					type: 'select',
					name: 'accessoryGroup',
					itemText: 'name',
					itemValue: 'id',
					items: this.accessoryGroups,
				},
				{
					label: 'Archived',
					type: 'toggle',
					name: 'archived',
					required: true,
				},
			]
		}
	},
	watch: {
		createDialog(updated) {
			if (!updated) {
				this.$refs.newCategoryForm.reset()
				this.$refs.newCategoryForm.resetValidation()
			}
		},
		createStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.listCategories()
				this.createDialog = false
			}
		},
		archiveStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.listCategories()
				this.archiveId = null
			}
		},
		restoreStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.archivedCategories()
				this.restoreId = null
			}
		},
		filters: {
			handler(updated) {
				this.applyFilters({...updated})
			},
			deep: true
		}
	}
}
</script>

<style>
.v-data-table-header-mobile {
	display: none;
}
</style>
